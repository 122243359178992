<template>
    <div>
        <b-row>
            <b-col sm="5">
                <b-card>
                    <b-row>
                        <b-col cols="12" class="my-1">
                            <h5>CANTIDAD DE FORMULARIOS POR NIVEL OBTENIDO (RANGO DE PUNTAJES)</h5>    
                        </b-col>
                        <b-col sm="12">
                            <b-row class="text-center">
                                <b-col sm="4" md="2" class="my-auto">                                    
                                    <feather-icon icon="CalendarIcon" size="1.5x" class="mr-1" style="color: rgba(216, 164, 0, 0.85);"  /> De
                                </b-col>
                                <b-col sm="8" md="3" class="my-auto">                                    
                                    <flat-pickr
                                        v-model="starDateQuinto"
                                        class="form-control"
                                        :config="configCalendar"
                                    />        
                                </b-col>
                                <b-col sm="4" md="1" class="my-auto"> 
                                    a
                                </b-col>
                                <b-col sm="8" md="3" class="my-auto">
                                    <flat-pickr
                                        v-model="endDateQuinto"
                                        class="form-control"
                                        :config="configCalendar"
                                    />        
                                </b-col>    
                                <b-col sm="12" md="3" class="my-auto"> 
                                   <b-button
                                        size="sm"
                                        variant="primary"
                                        @click="filterQuinto"
                                    >                                    
                                     <feather-icon icon="FilterIcon" size="1x"/>   
                                       Filtrar
                                   </b-button>
                                </b-col>                            
                            </b-row>                            
                        </b-col>                 
                        <b-col cols="12">
                            <vue-apex-charts
                                ref="pieQuintoRef"
                                type="bar"
                                height="325"
                                class="mt-2 mb-1"
                                :options="tablePieQuinto.chartOptions"
                                :series="tablePieQuinto.series"
                            />                            
                        </b-col>                    
                    </b-row>
                </b-card>                                           
            </b-col>
            <b-col sm="7">
                <b-card>                     
                    <json-excel :data="quintoTableExport()" name="cantidad_formularios_por_nivel_obtenido.xls"></json-excel>
                    <b-table
                        ref="table-quinto-ref"
                        :fields="fieldsQuinto"
                        :items="itemsQuinto"
                        show-empty
                        empty-text="No se encontraron registros coincidentes"
                    >   

                        <template #cell(level_name)="data">
                            <b class="text-primary" v-if="data.item.status">{{ data.item.level_name }}</b>
                            <span v-else>{{ data.item.level_name }}</span>                       
                        </template>
                    
                        <template #cell(quantity)="data">
                            <b class="text-primary" v-if="data.item.status">{{ data.item.quantity }}</b>
                            <span v-else>{{ data.item.quantity }}</span>
                        </template>
                        <template #cell(percentage)="data">
                            <b class="text-primary" v-if="data.item.status">{{ data.item.percentage }}</b>
                            <span v-else>{{ data.item.percentage }}</span>                       
                        </template>
                    
                    </b-table>
                </b-card>                
            </b-col>
        </b-row>
    </div>  
</template>

<script>

    import {Spanish as SpanishLocale} from 'flatpickr/dist/l10n/es.js';
    import flatPickr from 'vue-flatpickr-component';
    import VueApexCharts from 'vue-apexcharts';
    import JsonExcel from "./download-excel";
    import axios from 'axios';

    export default {
        components: {
            flatPickr,
            VueApexCharts,
            JsonExcel
        },
        data() {
            return {
                totalQuinto: "",
                starDateQuinto: "",
                endDateQuinto: "",
                configCalendar: {
                    locale:  SpanishLocale,
                    dateFormat: 'd-m-Y'
                },
                fieldsQuinto: [
                    {key: 'index', label: "#"},
                    {key: "level_name", label: "NIVEL OBTENIDO"},
                    {key: "quantity", label: "Cantidad"},
                    {key: "percentage", label: "%"}
                ],
                itemsQuinto: [],
                tablePieQuinto: {
                    chartOptions: {
                        chart: {
                            type: 'bar',
                            toolbar: {
                                show: false
                            }
                        },
                        dataLabels: {
                            enabled: true
                        },
                        legend: { show: false },
                        stroke: {
                            width: 4
                        },
                        noData: { text: "Sin datos..." }
                    },
                    series: [{
                        name: 'Cantidad',
                        data: []
                    }]
                },
            }
        },
        beforeMount() {
            this.getDataTableQuinto();
        },
        methods: {
            async getDataTableQuinto (){

                let response = await axios .get(`${process.env.VUE_APP_HOST}/api/dashboard/reporteria/levels-forms?filterStartDate=${this.starDateQuinto}&filterEndDate=${this.endDateQuinto}`);
               
                let total = 0;
                response.data.map(item => { total = total + item.forms_count } );
                                
                this.itemsQuinto = [];
                this.tablePieQuinto.series[0].data = [];

                response.data.map( (item, index) => {

                    this.itemsQuinto.push({
                        index: index + 1,
                        level_name: item.name,
                        quantity: item.forms_count,
                        percentage: ((item.forms_count*100)/total).toFixed(2) + '%'
                    });

                    this.tablePieQuinto.series[0].data.push({
                        x: item.name,
                        y: item.forms_count
                    });

                });

                this.itemsQuinto.push({
                    index: '',
                    level_name: 'TOTAL',
                    quantity: total,
                    percentage: '100%',
                    status: true
                });
                
                this.totalQuinto = total;
                await this.$refs["pieQuintoRef"].refresh();
            },
            async filterQuinto (){
                await this.getDataTableQuinto();
                this.$refs['table-quinto-ref'].refresh();
            },           
            quintoTableExport (){
                
                let array = [];

                for (let a=0; a<this.itemsQuinto.length; a++)
                {
                    let objBody = {
                        'ITEM': a+1,
                        'NIVEL OBTENIDO': this.itemsQuinto[a]["level_name"],
                        'CANTIDAD': this.itemsQuinto[a]["quantity"],
                        'PORCENTAJE':  this.itemsQuinto[a]["percentage"]
                    };

                    array.push(objBody);
                }
                
                array.splice(array.length-1, 1);

                let objFoot = {
                    'ITEM': '',
                    'NIVEL OBTENIDO': 'TOTAL',
                    'CANTIDAD': this.totalQuinto,
                    'PORCENTAJE': '100%'
                };

                array.push(objFoot);
                
                return array;
            },
        }
    }

</script>

<style scoped>

    input[type=text] {
        border: none;
        border-radius: 0px;
        background: transparent;
        border-bottom: 1px solid #d8d6de;
    }

</style>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-flatpicker.scss';
    @import '@core/scss/vue/libs/chart-apex.scss';
</style>
